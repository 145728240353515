import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Text = styled.div`
  flex: 100%;
  width: 100%;

  padding: 42px 0 60px 0;
  z-index: 100;

  @media (max-width: 920px) {
    padding: 22px 0 60px 0;
  }

  & p {
    font-size: calc(40px + (60 - 40) * ((100vw - 800px) / (1600 - 800)));
    line-height: 151%;

    letter-spacing: 0.08em;
    text-transform: uppercase;

    margin: 0;
  }

  & em {
    font-style: normal;
    color: #fff;
  }

  & .line-one,
  & .line-three {
    text-align: left;
  }

  & .line-two {
    text-align: right;
  }

  & .line-one,
  & .line-two,
  & .line-three {
    transition: 250ms opacity ease;
  }

  & .line-one {
    opacity: ${props => props.lineOne};
  }

  & .line-two {
    opacity: ${props => props.lineTwo};
  }

  & .line-three {
    opacity: ${props => props.lineThree};
  }
`;

export const DesktopText = ({ data, hasTextLoaded, setTextHasLoaded }) => {
  const [lineOneOpacity, setLineOneOpacity] = useState(0);
  const [lineTwoOpacity, setLineTwoOpacity] = useState(0);
  const [lineThreeOpacity, setLineThreeOpacity] = useState(0);

  useEffect(() => {
    if (hasTextLoaded === false) {
      const lineOneTimer = setTimeout(() => {
        setLineOneOpacity(1);

        const lineTwoTimer = setTimeout(() => {
          setLineTwoOpacity(1);

          const lineThreeTimer = setTimeout(() => {
            setLineThreeOpacity(1);
            setTextHasLoaded(true);
          }, 350);
          return () => clearTimeout(lineThreeTimer);
        }, 350);
        return () => clearTimeout(lineTwoTimer);
      }, 350);
      return () => clearTimeout(lineOneTimer);
    } else {
      setLineOneOpacity(1);
      setLineTwoOpacity(1);
      setLineThreeOpacity(1);
    }
  }, [hasTextLoaded]);

  return (
    <Text
      lineOne={lineOneOpacity}
      lineTwo={lineTwoOpacity}
      lineThree={lineThreeOpacity}
      id="text-container"
    >
      <div
        className="line-one beatrice"
        dangerouslySetInnerHTML={{
          __html: data.text_line_one.html,
        }}
      />
      <div
        className="line-two beatrice"
        dangerouslySetInnerHTML={{
          __html: data.text_line_two.html,
        }}
      />
      <div
        className="line-three beatrice"
        dangerouslySetInnerHTML={{
          __html: data.text_line_three.html,
        }}
      />
    </Text>
  );
};
