import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Text = styled.div`
  flex: 100%;
  width: 100%;

  padding: 9px 0 40px 0;

  & p {
    font-size: calc(40px + (60 - 40) * ((100vw - 800px) / (1600 - 800)));
    line-height: 144%;

    letter-spacing: 0.08em;
    text-transform: uppercase;

    margin: 0;

    & a {
      cursor: url(/icons/cursor-large@1x.png) 30 30, pointer;
      cursor: -webkit-image-set(
            url(/icons/cursor-large@1x.png) 1x,
            url(/icons/cursor-large@2x.png) 2x
          )
          30 30,
        pointer;
    }
  }

  & em {
    font-style: normal;
    color: #fff;
  }

  & .line-one,
  & .line-three,
  & .line-five {
    text-align: left;
  }

  & .line-two,
  & .line-four {
    text-align: right;
  }

  & .line-one,
  & .line-two,
  & .line-three,
  & .line-six {
    transition: 250ms opacity ease;
  }

  & .line-one {
    opacity: ${props => props.lineOne};
  }

  & .line-two {
    opacity: ${props => props.lineTwo};
  }

  & .line-three {
    opacity: ${props => props.lineThree};
  }

  & .line-four {
    opacity: ${props => props.lineFour};
  }

  & .line-five {
    opacity: ${props => props.lineFive};
  }

  & .line-six {
    opacity: ${props => props.lineSix};
  }
`;

export const MobileText = ({ data, hasTextLoaded, setTextHasLoaded }) => {
  const [lineOneOpacity, setLineOneOpacity] = useState(0);
  const [lineTwoOpacity, setLineTwoOpacity] = useState(0);
  const [lineThreeOpacity, setLineThreeOpacity] = useState(0);
  const [lineFourOpacity, setLineFourOpacity] = useState(0);
  const [lineFiveOpacity, setLineFiveOpacity] = useState(0);
  const [lineSixOpacity, setLineSixOpacity] = useState(0);

  useEffect(() => {
    if (hasTextLoaded === false) {
      const lineOneTimer = setTimeout(() => {
        setLineOneOpacity(1);

        const lineTwoTimer = setTimeout(() => {
          setLineTwoOpacity(1);

          const lineThreeTimer = setTimeout(() => {
            setLineThreeOpacity(1);

            const lineFourTimer = setTimeout(() => {
              setLineFourOpacity(1);

              const lineFiveTimer = setTimeout(() => {
                setLineFiveOpacity(1);

                const lineSixTimer = setTimeout(() => {
                  setLineSixOpacity(1);
                  setTextHasLoaded(true);
                }, 350);
                return () => clearTimeout(lineSixTimer);
              }, 350);
              return () => clearTimeout(lineFiveTimer);
            }, 350);
            return () => clearTimeout(lineFourTimer);
          }, 350);
          return () => clearTimeout(lineThreeTimer);
        }, 350);
        return () => clearTimeout(lineTwoTimer);
      }, 350);
      return () => clearTimeout(lineOneTimer);
    } else {
      setLineOneOpacity(1);
      setLineTwoOpacity(1);
      setLineThreeOpacity(1);
      setLineFourOpacity(1);
      setLineFiveOpacity(1);
      setLineSixOpacity(1);
    }
  }, [hasTextLoaded]);

  return (
    <Text
      lineOne={lineOneOpacity}
      lineTwo={lineTwoOpacity}
      lineThree={lineThreeOpacity}
      lineFour={lineFourOpacity}
      lineFive={lineFiveOpacity}
      lineSix={lineSixOpacity}
    >
      <div
        className="line-one beatrice"
        dangerouslySetInnerHTML={{
          __html: data.text_line_one_mobile.html,
        }}
      />
      <div
        className="line-two beatrice"
        dangerouslySetInnerHTML={{
          __html: data.text_line_two_mobile.html,
        }}
      />
      <div
        className="line-three beatrice"
        dangerouslySetInnerHTML={{
          __html: data.text_line_three_mobile.html,
        }}
      />
      <div
        className="line-four beatrice"
        dangerouslySetInnerHTML={{
          __html: data.text_line_four_mobile.html,
        }}
      />
      <div
        className="line-five beatrice"
        dangerouslySetInnerHTML={{
          __html: data.text_line_five_mobile.html,
        }}
      />
      <div
        className="line-six beatrice"
        dangerouslySetInnerHTML={{
          __html: data.text_line_six_mobile.html,
        }}
      />
    </Text>
  );
};
