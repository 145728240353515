import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
  margin: -5px 0 0 0;

  @media (max-width: 600px) {
    width: 20px;
    height: 20px;

    circle {
      stroke-width: 1.25px;

      r: 14px;
    }
  }
`;

export const FormAlert = () => (
  <Icon
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="15.625" stroke="black" strokeWidth="0.75" />
    <path
      d="M15.3801 10.148L15.5961 19.274H16.4241L16.6401 10.148H15.3801ZM15.2181 23H16.7841V21.434H15.2181V23Z"
      fill="black"
    />
  </Icon>
);
