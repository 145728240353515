import React, { useState, useEffect, useRef } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { withPreview } from "gatsby-source-prismic";
import { use100vh } from "react-div-100vh";
import { createBreakpoint, useMouse } from "react-use";

// Components
import { SignUp } from "../components/forms/sign-up";
import { DesktopText } from "../components/homepage/desktop-text";
import { MobileText } from "../components/homepage/mobile-text";

const Page = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  min-height: ${props => props.minHeight}px;

  & .cursor {
    position: fixed;
    background-color: #fff;
    border-radius: 100%;

    width: 20px;
    height: 20px;

    transition: 300ms width ease, 300ms height ease;

    z-index: 0;

    @media (hover: none) and (pointer: coarse) {
      display: none;
    }

    @media (max-width: 600px) {
      display: none;
    }
  }
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-self: flex-end;

  flex: 100%;
  width: 100%;

  padding: 0 0 35px 0;

  @media (max-width: 767px) {
    padding: 0 0 20px 0;
  }

  opacity: ${props => props.opacity};
  transition: 250ms opacity ease;

  & .follow-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-self: flex-end;

    order: 1;

    @media (max-width: 1140px) {
      order: 12;

      width: 100%;
    }

    @media (max-width: 710px) {
      justify-content: space-between;
    }

    & .follow-one,
    & .follow-two {
      margin: 40px 40px -4px 0;

      @media (max-width: 710px) {
        margin: 40px 0 0 0;
      }
    }

    & p {
      font-size: 15px;
      line-height: 21px;
      letter-spacing: 0.02em;

      margin: 0;

      @media (max-width: 600px) {
        font-size: 13px;
        line-height: 20px;
      }
    }
  }
`;

const useBreakpoint = createBreakpoint({ L: 600, M: 1 });

const Index = ({ data }) => {
  const [bottomOpacity, setBottomOpacity] = useState(0);
  const [hasTextLoaded, setTextHasLoaded] = useState(false);

  const height = use100vh();
  const breakpoint = useBreakpoint();

  const ref = useRef(null);
  const { docX, docY } = useMouse(ref);

  useEffect(() => {
    if (hasTextLoaded === true) {
      const bottomLineTimer = setTimeout(() => {
        setBottomOpacity(1);
      }, 350);
      return () => clearTimeout(bottomLineTimer);
    }
  }, [hasTextLoaded]);

  return (
    <>
      <Page minHeight={height} ref={ref}>
        <div
          className="cursor"
          style={{
            top: `${docY - 10}px`,
            left: `${docX - 10}px`,
          }}
        />

        {breakpoint === "L" && (
          <DesktopText
            data={data.prismicHoldingSite.data}
            hasTextLoaded={hasTextLoaded}
            setTextHasLoaded={setTextHasLoaded}
          />
        )}

        {breakpoint === "M" && (
          <MobileText
            data={data.prismicHoldingSite.data}
            hasTextLoaded={hasTextLoaded}
            setTextHasLoaded={setTextHasLoaded}
          />
        )}

        <Bottom opacity={bottomOpacity}>
          <div className="follow-container">
            <div
              className="follow-one"
              dangerouslySetInnerHTML={{
                __html: data.prismicHoldingSite.data.follow_link_one.html,
              }}
            />
            <div
              className="follow-two"
              dangerouslySetInnerHTML={{
                __html: data.prismicHoldingSite.data.follow_link_two.html,
              }}
            />
          </div>
          <SignUp />
        </Bottom>
      </Page>
    </>
  );
};

export default withPreview(Index);

export const query = graphql`
  {
    prismicHoldingSite {
      data {
        text_line_one {
          html
        }
        text_line_two {
          html
        }
        text_line_three {
          html
        }
        text_line_one_mobile {
          html
        }
        text_line_two_mobile {
          html
        }
        text_line_three_mobile {
          html
        }
        text_line_four_mobile {
          html
        }
        text_line_five_mobile {
          html
        }
        text_line_six_mobile {
          html
        }
        follow_link_one {
          html
        }
        follow_link_two {
          html
        }
      }
    }
  }
`;
