import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
  transform: rotate(${props => (props.isOpen ? "-180deg" : "0")});
  cursor: url(/icons/cursor-small@1x.png) 15 15, pointer;
  cursor: -webkit-image-set(
        url(/icons/cursor-small@1x.png) 1x,
        url(/icons/cursor-small@2x.png) 2x
      )
      15 15,
    pointer;

  @media (max-width: 600px) {
    width: 16px;
    height: auto;

    & path {
      stroke-width: 1.25px;
    }
  }
`;

export const DropdownIcon = ({ isOpen }) => (
  <Icon
    width="26"
    height="14"
    viewBox="0 0 26 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    isOpen={isOpen}
  >
    <path d="M25 1L13 13L1 1" stroke="#000" strokeWidth="0.8" />
  </Icon>
);
