import React, { useState, useEffect, useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";

// Context
import { PageColor } from "../context/page-color";

// Components
import { CountrySelector } from "./country-selector";

// Icons
import { FormAlert } from "../icons/form-alert";

// Utils
import { encodeForm } from "../utils/encode-form";

const EnquiryFormContainer = styled.div`
  position: relative;
  align-self: flex-end;

  order: 12;

  @media (max-width: 1140px) {
    order: 1;
  }

  @media (max-width: 600px) {
    width: 100%;
  }

  & .signup-text {
    margin: 0 0 35px 0;

    & p {
      @media (max-width: 600px) {
        font-size: 13px;
        line-height: 21px;
      }
    }
  }

  & .thank-you-message,
  & form {
    max-width: 670px;
    margin: 0;

    font-size: 17px;
    line-height: 34px;
    letter-spacing: 0.02em;

    @media (max-width: 600px) {
      font-size: 13px;
      line-height: 21px;
    }

    & .field {
      position: relative;
      z-index: 1;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      border-bottom: 1px solid #000;

      margin: 0 0 20px 0;

      & label {
        width: 230px;
        padding: 0;

        @media (max-width: 600px) {
          width: 100px;
        }
      }

      & input {
        width: calc(100% - 230px - 32px);

        padding: 0;
        margin: 0;
        border: 0;

        line-height: 34px;

        @media (max-width: 600px) {
          width: calc(100% - 100px - 20px);
          line-height: 20px;
        }
      }
    }
  }

  & .thank-you-message {
    line-height: 15px;

    & p {
      margin: 0;
    }
  }
`;

const OptionsContainer = styled.div`
  max-width: 670px;
  width: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  font-size: 17px;
  line-height: 34px;
  letter-spacing: 0.02em;

  & #country {
    width: calc(100% - 230px);
  }

  @media (max-width: 600px) {
    & #country {
      width: calc(100% - 100px);
    }

    font-size: 13px;
    line-height: 21px;
  }
`;

const PrivacyPolicy = styled.div`
  margin: 0;
  width: calc(100% - 230px);
  // max-width: 440px;
  order: 12;

  @media (max-width: 600px) {
    order: 1;
    width: 100%;
    max-width: 100%;
  }

  & p {
    font-size: 13px;
    line-height: 19px;
    letter-spacing: 0.02em;

    @media (max-width: 600px) {
      font-size: 11px;
      line-height: 19px;
    }

    & a {
      border-bottom: 1px solid #000;
    }
  }
`;

const SubmitButtonContainer = styled.div`
  & .top-line-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;

    margin: 60px 0 0 0;

    & p {
      margin: 0;
    }

    @media (max-width: 600px) {
      margin: 40px 0 0 0;
    }

    & button {
      border: 1px solid #000;

      text-align: center;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.08em;

      padding: 3px 10px;

      transition: 150ms all ease;
      color: #000;

      order: 1;

      @media (max-width: 600px) {
        order: 12;
        font-size: 13px;
        line-height: 21px;

        padding: 0 8px;
        margin: 40px 0 20px 0;
      }

      &:hover,
      &:focus,
      &:focus-within {
        color: ${props => props.buttonHoverColor};
        background-color: #000;

        -webkit-text-stroke: 0.1px ${props => props.buttonHoverColor};
      }

      &:disabled,
      &[disabled] {
        &:hover,
        &:focus,
        &:focus-within {
          color: #000;
          background-color: transparent;

          -webkit-text-stroke: 0.1px #000;
        }
      }
    }
  }
`;

async function userSubscribeToMailchimp(data) {
  const subscribeMailchimp = await fetch(`/api/subscribe`, {
    method: "POST",
    body: JSON.stringify({
      fName: data.fName,
      lName: data.lName,
      email: data.email,
      country: data.country,
    }),
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });
  let getSubscriberData = await subscribeMailchimp;
  return getSubscriberData;
}

async function userUpdateMailchimp(data) {
  const updateMailchimpSubscriber = await fetch(`/api/update-subscriber`, {
    method: "PATCH",
    body: JSON.stringify({
      fName: data.fName,
      lName: data.lName,
      email: data.email,
      country: data.country,
    }),
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  });
  let getUpdateMailchimpSubscriber = await updateMailchimpSubscriber;
  return getUpdateMailchimpSubscriber;
}

async function userUpdateTagsMailchimp(data) {
  const updateMailchimpSubscriberTags = await fetch(
    `/api/update-subscriber-tags`,
    {
      method: "POST",
      body: JSON.stringify({
        fName: data.fName,
        lName: data.lName,
        email: data.email,
        country: data.country,
      }),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    }
  );
  let getUpdateMailchimpSubscriberTags = await updateMailchimpSubscriberTags;
  return getUpdateMailchimpSubscriberTags;
}

export const SignUp = () => {
  const [pageColor, setPageColor] = useContext(PageColor);
  const [isFormVisible, setIsFormVisible] = useState(true);

  const data = useStaticQuery(graphql`
    {
      prismicSignupForm {
        data {
          signup_text {
            html
          }
          privacy_policy_text {
            html
          }
          button_call_to_action
          success_message {
            html
          }
        }
      }
    }
  `);

  // Form Setup
  const {
    control,
    register,
    handleSubmit,
    errors,
    setError,
    setValue,
    formState,
    getValues,
  } = useForm();

  async function onSubmit(data, e) {
    e.preventDefault();

    const signupUserResult = await userSubscribeToMailchimp(data);
    const initialSignupResponse = await signupUserResult.json();

    if (signupUserResult.status === 200) {
      // eslint-disable-next-line
      const updateUsersTags = await userUpdateTagsMailchimp(data);
      setIsFormVisible(false);
      e.target.reset();
    }

    if (
      signupUserResult.status === 400 &&
      initialSignupResponse.data.title === "Member Exists"
    ) {
      // eslint-disable-next-line
      const updateUserResult = await userUpdateMailchimp(data);
      setIsFormVisible(false);

      // eslint-disable-next-line
      const updateUsersTags = await userUpdateTagsMailchimp(data);
      setIsFormVisible(false);
      e.target.reset();
    }

    if (
      signupUserResult.status === 400 &&
      initialSignupResponse.data.title === "Invalid Resource"
    ) {
      setValue("email", "");
      setError("email", "Enter a valid e-mail address");

      fetch("/", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: encodeForm({
          "form-name": `signup-form`,
          ...data,
        }),
      });
    }
  }

  useEffect(() => {
    if (isFormVisible === false) {
      const fadeOutForm = setTimeout(() => {
        const resetFormVisbility = setTimeout(() => {
          setIsFormVisible(true);
        }, 1000);
        return () => clearTimeout(resetFormVisbility);
      }, 8000);
      return () => clearTimeout(fadeOutForm);
    }
  }, [isFormVisible]);

  //Suppress default ui bubble error messages
  useEffect(() => {
    if (typeof window !== undefined) {
      document.querySelector("form").addEventListener(
        "invalid",
        function(event) {
          event.preventDefault();

          const values = getValues();

          if (values.fName === "") {
            setError("fName", "This field is required");
          }

          if (values.lName === "") {
            setError("lName", "This field is required");
          }

          setValue("email", "");
          setError("email", "Enter a valid e-mail address");
        },
        true
      );
    }
  }, []);

  // Read the formState before render to subscribe the form state through Proxy
  const { dirtyFields } = formState;

  return (
    <EnquiryFormContainer className="z-index-100">
      {isFormVisible && (
        <>
          <div
            className="signup-text"
            dangerouslySetInnerHTML={{
              __html: data.prismicSignupForm.data.signup_text.html,
            }}
          />

          <form
            name="signup-form"
            id="holding_site_signup_form"
            method="POST"
            data-netlify="true"
            netlify-honeypot="bot-field"
            onSubmit={handleSubmit(onSubmit)}
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="signup-form" />
            <div className="field">
              <label htmlFor="fName">First Name</label>
              <input
                id="fName"
                name="fName"
                type="fName"
                autoComplete="given-name"
                aria-required="true"
                aria-label="First Name"
                placeholder={errors.fName ? `This field is required` : ``}
                ref={register({
                  required: true,
                })}
              />
              {errors.fName && <FormAlert />}
            </div>

            <div className="field">
              <label htmlFor="lName">Last Name</label>
              <input
                id="lName"
                name="lName"
                type="text"
                autoComplete="family-name"
                aria-required="true"
                aria-label="Last Name"
                placeholder={errors.lName ? `This field is required` : ``}
                ref={register({
                  required: true,
                })}
              />
              {errors.lName && <FormAlert />}
            </div>

            <div className="field">
              <label htmlFor="email">E-mail</label>
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                aria-required="true"
                aria-label="E-mail"
                placeholder={errors.email ? `Enter a valid e-mail address` : ``}
                ref={register({
                  required: true,
                })}
              />
              {errors.email && <FormAlert />}
            </div>

            <div className="field">
              <OptionsContainer>
                <label htmlFor="country" id="downshift-0-label">
                  Country
                </label>

                <Controller
                  control={control}
                  name="country"
                  defaultValue="United States"
                  render={({ onChange, onBlur, value }) => (
                    <CountrySelector
                      onChange={onChange}
                      selected={value}
                      highlightColor={pageColor}
                    />
                  )}
                />
              </OptionsContainer>
            </div>

            <SubmitButtonContainer buttonHoverColor={pageColor}>
              <div className="errors-container">
                {(errors.fName || errors.lName || errors.email) && (
                  <p>Please complete the missing information</p>
                )}
              </div>

              <div className="top-line-container">
                <button type="submit" className="submit-button" tabIndex="0">
                  {data.prismicSignupForm.data.button_call_to_action}
                </button>

                <PrivacyPolicy
                  dangerouslySetInnerHTML={{
                    __html:
                      data.prismicSignupForm.data.privacy_policy_text.html,
                  }}
                />
              </div>
            </SubmitButtonContainer>
          </form>
        </>
      )}

      {!isFormVisible && (
        <div className="thank-you-message" id="thank_you_message">
          <div
            dangerouslySetInnerHTML={{
              __html: data.prismicSignupForm.data.success_message.html,
            }}
          />
        </div>
      )}
    </EnquiryFormContainer>
  );
};
